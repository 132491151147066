import React, { useState ,useEffect} from "react";
import './EventStyle.css';
import  {getEventList} from "../../services/AWS/competitionInformation/CompetitionService";
import { Link, useNavigate } from "react-router-dom";
import LoadingOverlay from "../loadingPage/LoadingPage";
import { createBreakpoint } from "react-use";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const headerStyle = {
    "font-size": "12px",
    "font-weight": "bold"
}
const cellStyle = {
    "font-size": "12px",
}
const  ListingElementWeb = (prop) =>{
    return(
    
        <div style={{ width: "90%" , "margin-left": "3%",marginBottom:"10px"}}>
            <TableContainer component={Paper}  sx={{fontWeight:"bold",borderRadius:"10px"}}>
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell >Competition Name</TableCell>
                            <TableCell align="left">Competition Date</TableCell>
                            <TableCell align="left">Participant Limit</TableCell>
                            <TableCell align="left">WCA Website</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prop.competitionInfo.map((row) => (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell  numeric component="a" target="_blank" href={`/Competition/Info/${row.competition_ID}`} scope="row">
                                    {row.Competition_name}
                                </TableCell>
                                <TableCell align="left">{row.Competition_date}</TableCell>
                                <TableCell align="left">{row.approved_count}/{row.Participant_limit}</TableCell>
                                <TableCell numeric component="a" target="_blank" href={`https://${row.wcaURL}`} align="left">{row.wcaURL}</TableCell>
                                <TableCell  numeric component="a" target="_blank" href={`/Competition/register/${row.competition_ID}`} scope="row">
                                    Register Here
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
              </div>
    )
    }
const  ListingElementMobile = (prop) =>{
    return(

        <div style={{ width: "100%",marginBottom:"10px" }}>
            <TableContainer sx={{borderRadius:"30px"}} component={Paper}>
                <Table sx={{ minWidth: 300 }} >
                    <TableHead>
                        <TableRow >
                            <TableCell sx={headerStyle} >Competition Name</TableCell>
                            <TableCell sx={headerStyle} align="left">Participant Limit</TableCell>

                            <TableCell sx={headerStyle} align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prop.competitionInfo.map((row) => (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell  sx={cellStyle}  numeric component="a"  sx={{fontSize:12}} href={`/Competition/Info/${row.competition_ID}`} scope="row">
                                    {row.Competition_name}
                                </TableCell>
                                <TableCell sx={cellStyle}  align="left">{row.approved_count}/{row.Participant_limit}</TableCell>
                                <TableCell sx={cellStyle} numeric component="a" href={`/Competition/register/${row.competition_ID}`} scope="row">
                                    Register Here
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const EventElement = (props) => {
    return (
        <div >
            <p ><Link to={`/Competition/Info/${props.competitionInfo.competition_ID}`} className="Link">{props.competitionInfo.Competition_name}</Link></p>
        </div>
    )
    }
const EventPage = () =>{
    const useBreakpoint = createBreakpoint();
    const breakpoint = useBreakpoint();
    const isMobile = breakpoint === "tablet";
    const [eventList,setEventList] = useState([""]);
    const [isLoading,setIsLoading] = useState(false);
    useEffect(() => {
        getCompetitionList("Active");
      },[]);
   

    const  getCompetitionList =async (active) =>{
        setIsLoading(true);
        const eventList2 = await getEventList(active);
       setEventList(eventList2);
       setIsLoading(false);
     }
    return (
        <div>
            <LoadingOverlay isLoading={isLoading}/>
            <div>
                <h1 style={{"margin-left": "40%"}}>Events</h1>
                {isMobile ? <ListingElementMobile competitionInfo={eventList}/> :
                    <ListingElementWeb competitionInfo={eventList}/>}
                {eventList.length === 0 &&
                    <p1 style={{"margin-left": "40%", minWidth: "70vh"}}>There is no upcoming competition, please come
                        back later</p1>}


            </div>

        </div>
    )

}
export default EventPage;